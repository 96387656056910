.m-auto {
  margin: auto;
}
.m-0 {
  margin: 0;
}
.m-1 {
  margin: 2px;
}
.m-2 {
  margin: 3px;
}
.m-3 {
  margin: 5px;
}
/* BOTTOM */
.mb-auto {
  margin-bottom: auto;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 2px;
}
.mb-2 {
  margin-bottom: 3px;
}
.mb-3 {
  margin-bottom: 5px;
}
.mb-4 {
  margin-top: 7px;
}
.mb-5 {
  margin-top: 9px;
}
/* TOP */
.mt-auto {
  margin-top: auto;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 3px;
}
.mt-3 {
  margin-top: 5px;
}
.mt-4 {
  margin-top: 7px;
}
.mt-5 {
  margin-top: 9px;
}

.m-revert{
  margin: revert;
}

@media (min-width: 576px) {
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-0 {
    margin: 0;
  }
  .m-sm-1 {
    margin: 2px;
  }
  .m-sm-2 {
    margin: 3px;
  }
  .m-sm-3 {
    margin: 5px;
  }
  
  /* BOTTOM */
  .mb-sm-auto {
    margin-bottom: auto;
  }
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .mb-sm-1 {
    margin-bottom: 2px;
  }
  .mb-sm-2 {
    margin-bottom: 3px;
  }
  .mb-sm-3 {
    margin-bottom: 5px;
  }
  .mb-sm-4 {
    margin-top: 7px;
  }
  .mb-sm-5 {
    margin-top: 9px;
  }
  
  /* TOP */
  .mt-sm-auto {
    margin-top: auto;
  }
  .mt-sm-0 {
    margin-top: 0;
  }
  .mt-sm-1 {
    margin-top: 2px;
  }
  .mt-sm-2 {
    margin-top: 3px;
  }
  .mt-sm-3 {
    margin-top: 5px;
  }
  .mt-sm-4 {
    margin-top: 7px;
  }
  .mt-sm-5 {
    margin-top: 9px;
  }

  .m-sm-revert{
    margin: revert;
  }
}

@media (min-width: 768px) {
  .m-md-auto {
    margin: auto;
  }
  .m-md-0 {
    margin: 0;
  }
  .m-md-1 {
    margin: 2px;
  }
  .m-md-2 {
    margin: 3px;
  }
  .m-md-3 {
    margin: 5px;
  }
  
  /* BOTTOM */
  .mb-md-auto {
    margin-bottom: auto;
  }
  .mb-md-0 {
    margin-bottom: 0;
  }
  .mb-md-1 {
    margin-bottom: 2px;
  }
  .mb-md-2 {
    margin-bottom: 3px;
  }
  .mb-md-3 {
    margin-bottom: 5px;
  }
  .mb-md-4 {
    margin-top: 7px;
  }
  .mb-md-5 {
    margin-top: 9px;
  }
  
  /* TOP */
  .mt-md-auto {
    margin-top: auto;
  }
  .mt-md-0 {
    margin-top: 0;
  }
  .mt-md-1 {
    margin-top: 2px;
  }
  .mt-md-2 {
    margin-top: 3px;
  }
  .mt-md-3 {
    margin-top: 5px;
  }
  .mt-md-4 {
    margin-top: 7px;
  }
  .mt-md-5 {
    margin-top: 9px;
  }

  .m-md-revert{
    margin: revert;
  }
}
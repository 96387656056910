h1 {
  font-family: var(--font-primary);
  font-size: 2.5em;
  font-weight: 200;
}
h1 > span {
  font-size: 2.5rem;
}
h2 {
  font-family: var(--font-primary);
  font-size: 2em;
  font-weight: 200;
}
h3 {
  font-family: var(--font-primary);
  font-size: 1.75em;
  font-weight: 100;
}
h4 {
  font-family: var(--font-primary);
  font-size: 1.75em;
  font-weight: 100;
}
p {
  font-family: var(--font-primary);
  font-weight: 100;
  font-size: 25px;
  line-height: 40px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.text-end {
  text-align: end !important;
}
.text-center {
  text-align: center !important;
}

:root {
  --indigo: #6610f2;
  --indigo-100: rgb(224, 208, 251);
  --indigo-200: rgb(194, 162, 248);
  --indigo-300: rgb(163, 117, 244);
  --indigo-400: rgb(133, 73, 241);
  --indigo-500: rgb(102, 40, 238);
  --indigo-600: rgb(82, 31, 191);
  --indigo-700: rgb(61, 22, 143);
  --indigo-800: rgb(41, 12, 96);
  --indigo-900: rgb(20, 5, 47);
}
/* TEXT COLORS */
.color-indigo {
  color: var(--indigo) !important;
}
.color-indigo-100 {
  color: var(--indigo-100) !important;
}
.color-indigo-200 {
  color: var(--indigo-200) !important;
}
.color-indigo-300 {
  color: var(--indigo-300) !important;
}
.color-indigo-400 {
  color: var(--indigo-400) !important;
}
.color-indigo-500 {
  color: var(--indigo-500) !important;
}
.color-indigo-600 {
  color: var(--indigo-600) !important;
}
.color-indigo-700 {
  color: var(--indigo-700) !important;
}
.color-indigo-800 {
  color: var(--indigo-800) !important;
}
.color-indigo-900 {
  color: var(--indigo-900) !important;
}
/* BG COLORS */
.bg-indigo {
  background-color: var(--indigo) !important;
}
.bg-indigo-100 {
  background-color: var(--indigo-100) !important;
}
.bg-indigo-200 {
  background-color: var(--indigo-200) !important;
}
.bg-indigo-300 {
  background-color: var(--indigo-300) !important;
}
.bg-indigo-400 {
  background-color: var(--indigo-400) !important;
}
.bg-indigo-500 {
  background-color: var(--indigo-500) !important;
}
.bg-indigo-600 {
  background-color: var(--indigo-600) !important;
}
.bg-indigo-700 {
  background-color: var(--indigo-700) !important;
}
.bg-indigo-800 {
  background-color: var(--indigo-800) !important;
}
.bg-indigo-900 {
  background-color: var(--indigo-900) !important;
}

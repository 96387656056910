/* X-Small devices (portrait phones, less than 576px) */
.container,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-fluid {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0 5px;
}
.container-faqstyle {
  margin: 60px 55px;
  display: flex;
  flex-direction: column;
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .container,
  .container-md {
    max-width: 720px;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .container,
  .container-lg {
    max-width: 960px;
  }
}
/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .container,
  .container-xl {
    max-width: 1140px;
  }
}
/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .container {
    width: 1320px;
  }
}

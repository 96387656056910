/* GENERALES */

input[type="radio"] {
  width: auto;
}
select {
  -webkit-appearance: none;
  appearance: none;
}
textarea {
  height: 20rem;
  resize: none;
}
.input:not([type="submit"]),
.textarea,
.select {
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
  display: block;
  border: none;
  width: stretch;
}
.input.validate {
  background-color: var(--red-200);
}
/* ----------------------------------------------ESTILOS */

/* GREY */
.form-grey > p {
  font-size: 1.3rem;
  margin: 0;
}
.legend-grey {
  font-size: 2.4rem;
  color: #333333;
}
.label-grey {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
}
.input-grey:not([type="submit"]),
.textarea-grey,
.select-grey {
  background-color: #e1e1e1;
}
/* SHADOW */
.input-shadow:not([type="submit"]),
.textarea-shadow,
.select-shadow {
  box-shadow: 0 0 8px 0 rgba(144, 149, 161, 0.3);
}
.input-shadow.validate,
.textarea-shadow.validate,
.select-shadow.validate {
  background-color: var(--red-200);
  color: var(--red-300);
}

/* GENERALES */
.card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1rem;
  border-radius: 1rem;
  position: relative;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
}
.card-title {
  font-family: var(--font-family);
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.card > span {
  color: var(--gray-500);
}
/* ESTILOS */
.card.card-shadow {
  border: none;
  box-shadow: 0 0 8px 0 rgba(28, 31, 39, 0.2);
}
.card.card-border {
  padding: 2rem;
  border-left: 10px solid pink;
}
.card-portfolio {
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 1rem;
  box-shadow: 0 0 8px 0 rgba(144, 149, 161, 0.15);
}

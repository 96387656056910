@import url("./Global.css");

/* Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --font-family: "Roboto", sans-serif;
  --primary: rgb(255, 182, 45);
  --secondary: var(--gray-300);
  --success: var(--green);
  --info: var(--cyan);
  --warning: var(--yellow);
  --danger: var(--red);
  --light: var(--gray-100);
  --dark: var(--gray-900);
}

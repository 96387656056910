.progressbar {
  margin-top: 5px;
  background-color: rgba(122, 122, 122, 0.1);
  border-radius: 13px;
  /* (height of inner div) / 2 + padding */
  padding: 3px;
}
.progressbar > div {
  background-color: #2596be;
  /*width: 45%;*/
  /* Adjust with JavaScript */
  height: 20px;
  border-radius: 10px;
}

.progess {
  margin: 20px 0;
  width: 100%;
  background-color: rgba(144, 149, 161, 0.15);
  border-radius: 1rem;
  height: 6px;
  display: block;
}
.progess > .progress-bar {
  border-radius: 1rem;
  height: 6px;
  display: block;
}
progress {
  /* Reset the default appearance */
  -webkit-appearance: none;
  appearance: none;
}
progress[value] {
  width: 100%;
  height: 20px;
}
progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
progress[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(
      -45deg,
      transparent 33%,
      rgba(0, 0, 0, 0.1) 33%,
      rgba(0, 0, 0, 0.1) 66%,
      transparent 66%
    ),
    -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
    -webkit-linear-gradient(left, #09c, #f44);

  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

:root {
  --blue: #0d6efd;
  --blue-100: rgb(208, 227, 254);
  --blue-200: rgb(160, 198, 252);
  --blue-300: rgb(113, 170, 251);
  --blue-400: rgb(66, 142, 250);
  --blue-500: rgb(27, 115, 249);
  --blue-600: rgb(20, 92, 199);
  --blue-700: rgb(14, 69, 150);
  --blue-800: rgb(8, 46, 100);
  --blue-900: rgb(4, 23, 50);
}
/* TEXT COLORS */

.color-blue {
  color: var(--blue) !important;
}
.color-blue-100 {
  color: var(--blue-100) !important;
}
.color-blue-200 {
  color: var(--blue-200) !important;
}
.color-blue-300 {
  color: var(--blue-300) !important;
}
.color-blue-400 {
  color: var(--blue-400) !important;
}
.color-blue-500 {
  color: var(--blue-500) !important;
}
.color-blue-600 {
  color: var(--blue-600) !important;
}
.color-blue-700 {
  color: var(--blue-700) !important;
}
.color-blue-800 {
  color: var(--blue-800) !important;
}
.color-blue-900 {
  color: var(--blue-900) !important;
}
/* BG COLORS */
.bg-blue {
  background-color: var(--blue) !important;
}
.bg-blue-100 {
  background-color: var(--blue-100) !important;
}
.bg-blue-200 {
  background-color: var(--blue-200) !important;
}
.bg-blue-300 {
  background-color: var(--blue-300) !important;
}
.bg-blue-400 {
  background-color: var(--blue-400) !important;
}
.bg-blue-500 {
  background-color: var(--blue-500) !important;
}
.bg-blue-600 {
  background-color: var(--blue-600) !important;
}
.bg-blue-700 {
  background-color: var(--blue-700) !important;
}
.bg-blue-800 {
  background-color: var(--blue-800) !important;
}
.bg-blue-900 {
  background-color: var(--blue-900) !important;
}

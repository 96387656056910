.alert {
  background-color: gray;
  padding: 2rem;
  border-radius: 1rem;
}
.alert.alert-primary {
  background-color: var(--blue-100);
  color: var(--primary);
}

.alert.alert-secondary {
  background-color: var(--gray-200);
  color: var(--secondary);
}

.alert.alert-success {
  background-color: var(--green-100);
  color: var(--success);
}

.alert.alert-info {
  background-color: var(--info);
  color: var(--info);
}

.alert.alert-warning {
  background-color: var(--warning);
  color: var(--warning);
}

.alert.alert-danger {
  background-color: var(--red-100);
  color: var(--danger);
}

.alert.alert-light {
  background-color: var(--light);
  color: var(--light);
}

.alert.alert-dark {
  background-color: var(--dark);
  color: var(--dark);
}
.alert.alert-especial {
  background-color: var(--especial2);
  color: var(--white);
}
